var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-btn",
    {
      attrs: { icon: "" },
      on: {
        click: function ($event) {
          return _vm.openNewHelpWindow(_vm.name)
        },
      },
    },
    [_c("v-icon", [_vm._v("mdi-help-circle-outline")])],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }